export const MenuItems = [
    {
      title: 'Seeds',
      path: '/marketing',
      cName: 'dropdown-link'
    },
    {
      title: 'Salsas',
      path: '/consulting',
      cName: 'dropdown-link'
    },
    {
      title: 'Spices',
      path: '/design',
      cName: 'dropdown-link'
    }
  ];