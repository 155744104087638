import React from 'react';
import './Cards.css';

//import React,  { useEffect, useRef, useState } from 'react';
// function ScrollSection(props) {
//   const sectionRef = useRef(null);
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsVisible(entry.isIntersecting);
//       },
//       { rootMargin: "-300px" }
//     );

//     let observerRefValue = null;

//     if (sectionRef.current) {
//       observer.observe(sectionRef.current);
//       observerRefValue = sectionRef.current;
//     }

//     return () => {
//       if (observerRefValue) {
//         observer.unobserve(observerRefValue);
//       }
//     };
//   }, []);

//   return (
//     <div className="journey">
//       <div className="isVisible">
//         {isVisible ? <img className='journey-image' src='/images/planting.png' alt="planting"/>: ""}
//       </div>
//       {/* <div className='section'></div> */}
//       {/* <div className='box' ref={sectionRef}><img className='journey-image' src='/images/planting.png' alt="planting"/></div> */}
//     </div>
//   );
// };


// const useElementOnScreen = (options) => {
//   const containerRef = useRef(null)
//   const [ isVisibile, setIsVisible] = useState(false)

//   const callbackFunction = (entries) => {
//     const [ entry ] = entries
//     setIsVisible(entry.isIntersecting)
//   }

//   useEffect(() => {
//     const observer = new IntersectionObserver(callbackFunction,options)
//     if (containerRef.current) observer.observe(containerRef.current)

//       return () => {
//         if(containerRef.current) observer.unobserve(containerRef.current)
//       }
//   }, [containerRef,options])

//   return [containerRef,isVisibile]
// }

// function Scroller() {
// const [containerRef,isVisibile] = useElementOnScreen({
//   root: null,
//   rootMargin: "0px",
//   threshold:1.0
// })

// return (
//   <div className='box' ref={containerRef}><img className='journey-image' src='/images/planting.png' alt="planting"/></div>
// )
// }

function Cards() {
  return (
    <div className='cards'>
      <div className='journey'>
      <img className='journey-image' src='/images/planting.png' alt="planting"/>
      <div className='journey-text'>
      <p> 
        From our previous years harvest, we take the seeds from the most spicy and robust peppers.
        From there, we use our in house soil blend to assure we will have the healthiest and productive growth.
        We then carefully hand plant each pepper, noting the depth and compaction to set our harvest up for success.
      </p>
      </div>
    </div>
    <div className='journey'>
      <img className='journey-image' src='/images/harvesting.png' alt="harvesting" width={590} height={"auto"}/>
      <div className='journey-text'>
      <p> 
       Towards the end of the season, we are left with beautifully ripe peppers. We use the trusted and tried method of sampling from each plant to see the 
       spiciness and overall flavor depth. We note the most performant plants and peppers to extract the seeds and store for next years planting and begin the 
       processing.
      </p>
      </div>
    </div>
    <div className='journey'>
      <img className='journey-image' src='/images/processing2.jpg' alt="processing" width={590}/>
      <div className='journey-text'>
      <p> 
        At Papis Peppers, we produce seeds, spices and salsas. For our salsas we gather some of the freshest ingredients in the area and bring it together with our peppers.
        Our salsas do not contain any chemicals or dyes. We rely on the color of the peppers and the naturally preserving qualities of ph levels and canning to ensure a fresh, tasty
        and safe product.
      </p>
      </div>
    </div>
    </div>
  );
}

export default Cards;
